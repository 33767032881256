import React from "react";
import "./Fifth.css";

function Third({ content }) {

  return (
    <div>
      {
        content?.map(content =>
          <div className="fifth_Cont mt-10">
            <div className="fifth_Cont1">
              <div className="head5">{content?.name}</div>
              <div className="content5" dangerouslySetInnerHTML={{
                __html: content?.description,
              }}></div>
             
            </div>

            <div className="fifth_Cont2">
              <img src='/Image/image 5.png' alt="image" title="image" className="img5" />
            </div>
          </div>
        )}
    </div>
  );
}

export default Third;
