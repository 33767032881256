import React from 'react';
import './Fourth.css';
import { getProducts } from '../../services/productService';
import { useQuery } from '@tanstack/react-query';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Fourth({ tags }) {
  const { data: products } = useQuery({
    queryKey: ["tag_id", tags?.tag_id],
    queryFn: getProducts
  });
// console.log("check data all product", products)
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div>
      <div className="fourth_cont">
        <div className="slider-container mx-2 lg:mx-2 md:mx-2">
          {/* <h2 className='text-3xl font-bold'>{tags?.title}</h2> */}
          <h2 className='text-3xl font-bold mb-2' style={{color: "rgb(162, 7, 141)"}}>All Products</h2>
          {(products && products.data && products.data.length>0) &&
          <Slider {...sliderSettings}>
              {products?.data?.map((product) =>
                <div key={product.id} className="slider-item">
                <a href={`/product/${product?.slug}`}>
                  <img title="product"
                    src={product?.thumbnail_image}
                    alt={product?.product_name}
                    className='rounded-lg imagecrousel p-1 '
                  />
                  <p className=' font-normal line-clamp-1' style={{fontWeight:"500"}}>{product?.product_name}</p>
                </a>
                </div>
              )}
          </Slider>
            }
        </div>
      </div>
    </div>
  );
}

export default Fourth;

