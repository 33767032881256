import React from 'react';
import { Link } from 'react-router-dom';
import { getProducts } from '../../services/productService';
import { useQuery } from '@tanstack/react-query';

function Seventh({category}) {
  const { data: products } = useQuery({
    queryKey: ["category_id",category?.category_id],
    queryFn: getProducts
  }); 
  
  return (
    <div>
      <div className='mt-24' style={{ fontFamily: 'Rockwell' }}>
        <h5 className='font-bold text-center text-4xl'>{category?.title}</h5>
        <div className='mt-10 items-center justify-center gap-5 w-full px-10 grid sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 ' >
        {products &&
                products?.data?.slice(0,4).map((product) => (
            <div className='relative' key={product.product_id}>
               <a href={`/product/${product.slug}`}>
              <img
              className='rounded'
                src={product.thumbnail_image}
                alt={product.product_name}
                title="products"
              />
              {/* <div className='absolute inset-0 flex items-center justify-center'>
                <Link to="/add-to-cart">
                  <button className='bg-[#FBC44B] text-white py-2 px-6 rounded'>
                    Add to Cart
                  </button>
                </Link>
              </div> */}
              <div className='absolute inset-0 flex items-center justify-center '>
              <h5 className='text-lg bg-[#FBC44B] text-white py-2 px-2 rounded ' style={{ fontFamily: 'Rockwell' }}>
              {product.product_name}
              </h5>
              </div>
              {/* <span
                className=''
                style={{ color: '#952F8A', fontFamily: 'Rockwell' }}
              >
                $25. KG
              </span> */}
            </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Seventh;
