import React, { useEffect, useState } from 'react';
import Marquee from 'react-fast-marquee';
import axios from 'axios';
import './style.css';

function Certificate({ theme }) {
  const [certificateData, setCertificateData] = useState([]);

  const skillBoxStyle = {
    // boxShadow: `0px 0px 2px 2px`,
  };

  const handleCertificate = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/admin/getAllCertificates`
      );
      // console.log('Certificate Fetched', response.data);

      if (response.data.result === 1) {
        setCertificateData(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching certificate', error);
    }
  };

  useEffect(() => {
    handleCertificate();
  }, []);

  return (
    <div className="skills " style={{ backgroundColor: "white"}}>
      <div className="">
        <h1 className='text-3xl font-bold flex text-center mb-2' style={{ color: "rgb(162,7,141)" }}>Certified By</h1>
      </div>
      <div className="skillsContainer ">
        <div className="skill--scroll">
          <Marquee
            gradient={false}
            speed={80}
            pauseOnHover={true}
            pauseOnClick={true}
            delay={0}
            play={true}
            direction="left"
          >
            {certificateData.map((certificate) => (
              <div className="skill--box" key={certificate.id} style={skillBoxStyle}>
                <img src={certificate.certificate_image} alt={certificate.name} title="certificate" style={{borderRadius:"10px"}} className='imgSlide'/>
                <h3 style={{ color: "black", whiteSpace:"nowrap" }}>{certificate.name}</h3>
              </div>
            ))}
          </Marquee>
        </div>
      </div>
    </div>
  );
}

export default Certificate;
