import React, { useState, useRef, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getUserDetails, updateUserProfile } from '../../services/Users';
import { ToastContainer, toast } from 'react-toastify';
import { Helmet } from 'react-helmet';

const Profile = () => {

  const initialUserData = {
    firstName: 'Rahul',
    lastName: 'Kumar',
    email: '123@gmail.com',
    mobileNumber: '1234567890',
    username: 'Rahul00',
    gender: 'Male',
    avatar: null,
  };

  const queryClient = useQueryClient();
  const fileInputRef = useRef(null);
  const { data: user, isLoading, error } = useQuery({
    queryFn: getUserDetails,
    queryKey: ['user_profile_info'],
  });

  const mutateUserProfile = useMutation({
    mutationFn: updateUserProfile,
    mutationKey: ['upload_user_profile'],
    onSuccess: (data) => {
      if (data?.data?.result === 1) {
        // toast(data?.data?.msg);
        queryClient.invalidateQueries('user_profile_info');

      }
    },
    onError: (error) => {
      console.error(error);
      if (error.response && error.response.data && error.response.data.errors) {
        const errorMessage = error.response.data.errors;
        toast(errorMessage);
      } else {
        toast('Something went wrong');
      }
    },
  });

  useEffect(() => {
    if (mutateUserProfile.isSuccess) {
      toast('Profile Updated', {
        autoClose: 3000, // Set the duration to 3 seconds
      });
    }
  }, [mutateUserProfile.isSuccess]);
  const [changedFirstName, setChangedFirstName] = useState(null);
  const [changedLastName, setChangedLastName] = useState(null);
  const [changedPhoneNo, setChangedPhoneNo] = useState(null);
  const [changedGender, setChangedGender] = useState(null);
  const [editableFields, setEditableFields] = useState(false);
  const [changedEmail, setChangedEmail] = useState(null);
  const [changedUsername, setChangedUsername] = useState(null);

  useEffect(() => {
    if (user) {
      setChangedFirstName(user?.data?.data?.first_name);
      setChangedLastName(user?.data?.data?.last_name);
      setChangedPhoneNo(user?.data?.data?.phone_no);
      setChangedGender(user?.data?.data?.gender);
      setChangedUsername(user?.data?.data?.user_name);
      setChangedEmail(user?.data?.data?.email);

    }
  }, [user]);

  const toggleEditField = () => {
    setEditableFields(!editableFields);
  };


  const handleUploadProfile = (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append('profile_image', file);
    formData.append('first_name', user?.data?.data?.first_name);
    formData.append('email', user?.data?.data?.email);
    formData.append('user_name', user?.data?.data?.user_name);
    // formData.append('last_name', user?.data?.data?.last_name);
    // formData.append('gender', user?.data?.data?.gender);
    formData.append('phone_no', user?.data?.data?.phone_no);

    mutateUserProfile.mutate(formData);
  };

  const handleChangeName = () => {
    if (editableFields) {
      const updateData = {
        first_name: changedFirstName,
        email: changedEmail,
        user_name: changedEmail || changedPhoneNo,
        // last_name: changedLastName,
        phone_no: changedPhoneNo,
        // gender: changedGender,
      };

      mutateUserProfile.mutate(updateData);
      queryClient.invalidateQueries('user_profile_info');

      // Disable editableFields after saving
      setEditableFields(false);
    } else {
      // Enable editableFields when clicking Save Profile
      setEditableFields(true);
    }
  };
  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };
  return (<>
           <Helmet>
  <title>KUKUNAMKEEN | Kachori | SWEETS | Free Shipping | Indian Snacks</title>
        <meta name="description" content= "KUKUNAMKEEN - Your Destination for Authentic Indian Snacks. Explore our range of Kachori, Sweets, and more. Enjoy Free Shipping on all orders"/>
        <meta name="keywords" content="KUKUNAMKEEN, Kachori, Sweets, Free Shipping, Indian Snacks"/>
        <link rel="canonical" href={`https://kukunamkeen.in/profile`} />

</Helmet>
    <div>
      {isLoading && <p>Loading...</p>}
      {error && <p>Error: {error.message}</p>}
      {user && (
        <div className="lg:mt-32 md:mt-48 max-sm:mt-32 flex flex-col md:flex-row p-4 gap-5">
          <div className="md:w-1/4 bg-gray-200 p-4">
            <div className="text-center rounded-full relative">
            <img
            src={
              user?.data?.data?.profile_image ||
              'https://img.freepik.com/premium-photo/marine-biologist-digital-avatar-generative-ai_934475-9003.jpg?w=740'
            }
            alt="User Avatar"
            className="w-52 h-52 rounded-full mx-auto mb-4"
          />

              <h1 className="block text-lg font-semibold mb-2">Profile Picture:</h1>
              <div className="flex flex-col items-center justify-center p-3 text-lg bg-black text-white text-center">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleUploadProfile}
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                />
                <button type="button" onClick={handleUploadButtonClick}>
                  Upload a Profile Picture
                </button>
              </div>
            </div>
          </div>
          <div className="md:w-3/4 bg-gray-200 p-4">
          <h2 className="text-2xl font-semibold mb-4">Profile Information</h2>

          <form>
            <div className="flex mb-4 ">
              <div className="w-1/2 mr-2">
                <h2 className="block text-lg font-semibold">
                  Full Name:
                </h2>
                {editableFields ? (
                  <input
                    type="text"
                    value={changedFirstName}
                    onChange={(e) => setChangedFirstName(e.target.value)}
                    className="border p-2 w-full"
                  />
                ) : (
                  <div className="border p-2 w-full bg-gray-100 ">
                    <span className="mr-2">{user?.data?.data?.first_name}</span>
                  </div>
                )}
              </div>
              <div className="w-1/2">
                <h2 className="block text-lg font-semibold">
                  Mobile Number:
                </h2>
                {editableFields ? (
                  <input
                  type="tel"
                  maxLength={10}
                  value={changedPhoneNo}
                  onChange={(e) => {
                      const re = /^[0-9\b]+$/;
                      if (e.target.value === '' || re.test(e.target.value)) {
                          setChangedPhoneNo(e.target.value);
                      }
                  }}
                  className="border p-2 w-full"
              />
              
                ) : (
                  <div className="border p-2 w-full bg-gray-100 ">
                    <span className="mr-2">{user?.data?.data?.phone_no ? user?.data?.data?.phone_no : "N/A"}</span>
                  </div>
                )}
              </div>
              
            </div>
            <div className="flex mb-4 ">
            <div className="w-1/2 mr-2">
                <h2 className="block text-lg font-semibold ">Email:</h2>


                {editableFields ? (
                  <input
                    type="email"
                    value={changedEmail}
                    onChange={(e) => setChangedEmail(e.target.value)}
                    className="border p-2 w-full"
                  />
                ) : (
                  <div className="border p-2 w-full bg-gray-100 ">
                    <span className="mr-2">{user?.data?.data?.email ? user?.data?.data?.email : "N/A"}</span>
                  </div>
                )}


              </div>
              {/* <div className="w-1/2 ">
                <label className="block text-lg font-semibold ">Username:</label>
                <input
                  type="email"
                  value={changedEmail}
                  disabled
                  className="border p-2 w-full bg-gray-100 "
                />
              </div> */}
           
              

              
            </div>
            {/* <div className="flex mb-4"> */}
              {/* <div className="w-1/2 mr-4">
                <label className="block text-lg font-semibold">Username:</label>

                <input
                  type="text"
                  value={changedUsername}
                  disabled
                  //  onChange={(e)=>setChangedUsername(e.target.value)}
                  className="border p-2 w-full bg-gray-100"
                />
              </div> */}
        {/*      <div className="w-1/2 ">
                <label className="block text-lg font-semibold">Gender:</label>
                {editableFields ? (
                  <select
                    value={changedGender}
                    onChange={(e) => setChangedGender(e.target.value)}
                    className="border p-2 w-full bg-gray-100"
                  >
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                ) : (
                  <div className="flex bg-gray-100 items-center p-2">
                    <span className="mr-2">{user?.data?.data.gender}</span>
                  </div>
                )}
                </div>*/}
            {/* </div> */}
            <div className="flex  justiy-center " >
              {editableFields ? (
                <button
                  type="button"
                  onClick={handleChangeName}
                  className="bg-black text-white p-3 mx-auto w-1/3 max-sm:w-full text-lg  hover:bg-black"
                >
                  Save Profile
                </button>
              ) : (
                <button
                  type="button"
                  onClick={toggleEditField}
                  className="bg-black text-white text-lg  mx-auto p-3 max-sm:w-full  w-1/3  hover:bg-black"
                >
                  Edit Profile
                </button>
              )}
            </div>
          </form>
        </div>
        </div>
      )}
      <ToastContainer
      position="top-right"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />

    </div>
    </>
  );
};

export default Profile;
