import React from 'react';
import { useNavigate } from 'react-router-dom';
import { RiErrorWarningLine } from 'react-icons/ri';

function NotFound() {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleGoHome = () => {
    navigate('/');
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      <div className="flex items-center justify-center bg-white p-8 rounded-lg shadow-lg">
        <RiErrorWarningLine size={60} className="text-red-500 mb-4" />
        <div className="text-center ml-4">
          <h1 className="text-4xl font-bold text-gray-800 mb-2">404</h1>
          <p className="text-xl text-gray-600 mb-4">Page Not Found</p>
          <p className="text-gray-500 mb-8">
            Sorry, the page you are looking for does not exist or has been moved.
          </p>
          <div className="flex justify-center space-x-4">
            <button
              onClick={handleGoBack}
              className="px-4 py-2 bg-gray-800 text-white rounded hover:bg-gray-700"
            >
              Go Back
            </button>
            <button
              onClick={handleGoHome}
              className="px-4 py-2 bg-purple-600 text-white rounded hover:bg-purple-500"
            >
              Go Home
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
