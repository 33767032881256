import React, { useEffect, useState } from 'react'
import Footer from '../../Footer/Footer'
import { Helmet } from "react-helmet";
import './StaticPage.css';
import axios from "axios";
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';

function StaticPage() {
    const [data, setData] = useState(null);
    const params = useParams()
    // console.log(params)

    useEffect(() => {
        window.scrollTo(0,0);
         },[])
         
const handleData = async () => {
    try{
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/admin/getStaticPage`
        );
        let thisPageIndex = response.data.data.findIndex(el=>el.slug==params.slug)
        let thisPageData
            if(thisPageIndex==-1)
            {
                thisPageData = {exist:false}
            }else{
                thisPageData = {exist:true,...response.data.data[thisPageIndex]}
            }
            // console.log("Details fetched successfully", thisPageData);
            setData(thisPageData);
    } catch(error) {
console.log("Error fetching Page Details", error);
    }
};
useEffect(()=>{
    handleData();
},[params]);
    return (
        <>
          <Helmet>
          <title>{data?.meta_title}</title>
        <meta name="description" content={data?.meta_description}/>
        <meta name="keywords" content={data?.meta_keywords}/>
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-VY1YL2WN1S"></script>
  <script>
    {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-VY1YL2WN1S');
    `}
  </script>
  <script type="text/javascript">
                    {`
                    (function(c,l,a,r,i,t,y){
                        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                    })(window, document, "clarity", "script", "mdcd40ib4r");
                    `}
                </script>
        <meta charSet="utf-8" />
        {/* <title>Kuku Namkeen | {data?.subtitle}</title> */}
        <link rel="canonical" href={`https://kukunamkeen.in/staticpages/${data?.slug}`} />
        <script>
                    {`
                        !function(f,b,e,v,n,t,s)
                        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                        n.queue=[];t=b.createElement(e);t.async=!0;
                        t.src=v;s=b.getElementsByTagName(e)[0];
                        s.parentNode.insertBefore(t,s)}(window, document,'script',
                        'https://connect.facebook.net/en_US/fbevents.js');
                        fbq('init', '398356959855805');
                        fbq('track', 'PageView');
                    `}
                </script>
                <noscript>
                    {`
                        <img height="1" width="1" style="display:none"
                             src="https://www.facebook.com/tr?id=398356959855805&ev=PageView&noscript=1" />
                    `}
                </noscript>
      </Helmet>
            <div className='img_about'>
                {
                    data?.image?
                    <img className='img_static' src={`${process.env.REACT_APP_API_URL}/uploads/${data?.image}`} alt="image"/>
                    :
                    <></>
                }
                <h1 className='text-center text-2xl uppercase font-bold pt-5' style={{ fontFamily: "Tenor Sans, sans-serif", textDecoration: "underline #FBC44B " }}>{data?.subtitle}</h1>
                <div className='lg:px-24 max-md:px-8 md:px-10 max-sm:px-6 py-5'>
                    <p className='mt-6 text-md font-normal text-ellipsis'style={{fontFamily:"Montserrat,sans-serif", textAlign:"justify"}}
                    >
                       { parse(data?.description? data.description:`<p>Loading...</p>`) }
                    </p>
                </div>
            </div>

           
        </>
    )
}

export default StaticPage
