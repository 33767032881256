import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Herosection from "./Components/Herosection/Herosection";
import Login from './Components/Loginpage/Login';
import ForgotPassword from "./Components/ForgotPassword/ForgotPassword";
import Cart from './Components/Addtocart/Cart';
import ScrollToTop from './Components/ScrollToTop/ScrollToTop';
import Product from './Components/ProductOverview/Product';
import Preloader from './Preloader.jsx';
import Checkout from './Components/Checkoutpage/Checkout';
import OrderPlaced from  "./Components/Checkoutpage/OrderPlaced";
import ScrollToTopButton from './Components/ScrollToTop/ScrollToTop';
import Signup from './Components/Signuppage/Signup';
import Register from './Components/Signuppage/Register.js';
import Profile from './Components/UserProfile/Profile';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import KukuNamkeen from './Components/ProductsList/KukuNamkeen';
import Sweets from './Components/ProductsList/Sweets';
import Header from './Components/Header/Header';
import About from './Components/StaticPages/Aboutuspage/About';
import Contact from './Components/StaticPages/ContactUsPage/Contact';
import Faq from './Components/StaticPages/Faqpage/Faq';
import TermConditions from './Components/StaticPages/TermConditions';
import PrivacyPolicy from './Components/StaticPages/PrivacyPolicy';
import ReturnRefund from './Components/StaticPages/ReturnRefund';
import StaticPage from './Components/StaticPages/Static-page';
import Footer from './Components/Footer/Footer';
import ResetPassword from './Components/ResetPassword/ResetPassword';
import PlacedOrder from './Components/PlacedOrder/PlacedOrder';
import EnterOTP from './Components/ResetPassword/EnterOTP';
import StaticPages from './Components/StaticPages/StaticPage/StaticPage.jsx';
import Payment from './Components/Payment/Payment.js';
import PaymentError from './Components/Payment/PaymentError.js';
import Makingprocess from './Components/StaticPages/MakingProcess/Makingprocess.jsx';
import { Helmet } from "react-helmet";
import NotFound from './Components/NotFound/NotFound.jsx';

function App() {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      })
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Helmet>
    {/* Google Tag Manager */}
    <script async src="https://www.googletagmanager.com/gtag/js?id=G-VY1YL2WN1S"></script>
    <script type="text/javascript">
      {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-VY1YL2WN1S');
      `}
    </script>

    {/* Microsoft Clarity */}
    <script type="text/javascript">
      {`
        (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "mdcd40ib4r");
      `}
    </script>

    {/* Facebook Pixel */}
    <script type="text/javascript">
      {`
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '398356959855805');
        fbq('track', 'PageView');
      `}
    </script>
   
{/* 
    <script type="application/ld+json">
      {JSON.stringify({
        "@context": "http://schema.org",
        "@type": "Product",
        "name": "GULAB PAAK - [500gm]",
        "description": "Ingredients - Natural Rose petals, Cashew, Refined Sugar, Good preservatives.",
        "sku": "GULABPAK500",
        "category": "Sweet",
        "image": [
          "https://api.kukunamkeen.in/uploads/uploads/1706684841.jpg",
          "https://api.kukunamkeen.in/uploads/uploads/1706684842.jpg",
          "https://api.kukunamkeen.in/uploads/uploads/1706684843.jpg"
        ],
        "offers": [
          {
            "@type": "Offer",
            "price": "579.00",
            "priceCurrency": "INR",
            "availability": "http://schema.org/InStock",
            "seller": {
              "@type": "Organization",
              "name": "Kukunamkeen"
            }
          },
          {
            "@type": "Offer",
            "price": "699.00",
            "priceCurrency": "INR",
            "availability": "http://schema.org/InStock",
            "seller": {
              "@type": "Organization",
              "name": "Kukunamkeen"
            },
            "priceValidUntil": "2024-06-09",
            "priceSpecification": {
              "@type": "PriceSpecification",
              "discount": {
                "@type": "QuantitativeValue",
                "value": "120.00",
                "priceCurrency": "INR"
              }
            }
          }
        ],
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4",
          "reviewCount": "80"
        }
      })}
    </script>

    <script type="application/ld+json">
      {JSON.stringify({
        "@context": "http://schema.org",
        "@type": "Product",
        "name": "GULAB PAAK - [250gm]",
        "description": "Ingredients - Natural Rose petals, Cashew, Refined Sugar, Good preservatives.",
        "sku": "GULABPAK250",
        "category": "Sweet",
        "image": [
          "https://api.kukunamkeen.in/uploads/uploads/1706684987.jpg",
          "https://api.kukunamkeen.in/uploads/uploads/1706684986.jpg",
          "https://api.kukunamkeen.in/uploads/uploads/1706684985.jpg"
        ],
        "offers": [
          {
            "@type": "Offer",
            "price": "299.00",
            "priceCurrency": "INR",
            "availability": "http://schema.org/InStock",
            "seller": {
              "@type": "Organization",
              "name": "Kukunamkeen"
            }
          },
          {
            "@type": "Offer",
            "price": "349.00",
            "priceCurrency": "INR",
            "availability": "http://schema.org/InStock",
            "seller": {
              "@type": "Organization",
              "name": "Kukunamkeen"
            },
            "priceValidUntil": "2024-06-09",
            "priceSpecification": {
              "@type": "PriceSpecification",
              "discount": {
                "@type": "QuantitativeValue",
                "value": "50.00",
                "priceCurrency": "INR"
              }
            }
          }
        ],
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4",
          "reviewCount": "80"
        }
      })}
    </script>

    <script type="application/ld+json">
      {JSON.stringify({
        "@context": "http://schema.org",
        "@type": "Product",
        "name": "Kaju Katli - [500gm]",
        "description": "Ingredients - Cashew powder, Refined Sugar, Good preservatives, Silver leaf.",
        "sku": "KAJUKATLI500",
        "category": "Sweet",
        "image": "https://api.kukunamkeen.in/uploads/uploads/1713198710.jpg",
        "offers": [
          {
            "@type": "Offer",
            "price": "589.00",
            "priceCurrency": "INR",
            "availability": "http://schema.org/InStock",
            "seller": {
              "@type": "Organization",
              "name": "Kukunamkeen"
            }
          },
          {
            "@type": "Offer",
            "price": "699.00",
            "priceCurrency": "INR",
            "availability": "http://schema.org/InStock",
            "seller": {
              "@type": "Organization",
              "name": "Kukunamkeen"
            },
            "priceValidUntil": "2024-06-09",
            "priceSpecification": {
              "@type": "PriceSpecification",
              "discount": {
                "@type": "QuantitativeValue",
                "value": "110.00",
                "priceCurrency": "INR"
              }
            }
          }
        ],
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4",
          "reviewCount": "80"
        }
      })}
    </script>

    <script type="application/ld+json">
      {JSON.stringify({
        "@context": "http://schema.org",
        "@type": "Product",
        "name": "KAJU KATLI - [300GM]",
        "description": "Ingredients - Cashew powder, Refined Sugar, Good preservatives, Silver leaf.",
        "sku": "KAJUKATLI300",
        "category": "Sweet",
        "image": "https://api.kukunamkeen.in/uploads/uploads/1713590768.jpg",
        "offers": [
          {
            "@type": "Offer",
            "price": "359.00",
            "priceCurrency": "INR",
            "availability": "http://schema.org/InStock",
            "seller": {
              "@type": "Organization",
              "name": "Kukunamkeen"
            }
          },
          {
            "@type": "Offer",
            "price": "419.00",
            "priceCurrency": "INR",
            "availability": "http://schema.org/InStock",
            "seller": {
              "@type": "Organization",
              "name": "Kukunamkeen"
            },
            "priceValidUntil": "2024-06-09",
            "priceSpecification": {
              "@type": "PriceSpecification",
              "discount": {
                "@type": "QuantitativeValue",
                "value": "60.00",
                "priceCurrency": "INR"
              }
            }
          }
        ],
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4",
          "reviewCount": "80"
        }
      })}
    </script>

    <script type="application/ld+json">
      {JSON.stringify({
        "@context": "http://schema.org",
        "@type": "Product",
        "name": "Dry fruit kachori - [500gm]",
        "description": "Ingredients - Cashew, Almond, Sugar, Kesar.",
        "sku": "DRYFRUITKACHORI500",
        "category": "Snack",
        "image": "https://api.kukunamkeen.in/uploads/uploads/1712497754.jpg",
        "offers": [
          {
            "@type": "Offer",
            "price": "579.00",
            "priceCurrency": "INR",
            "availability": "http://schema.org/InStock",
            "seller": {
              "@type": "Organization",
              "name": "Kukunamkeen"
            }
          },
          {
            "@type": "Offer",
            "price": "699.00",
            "priceCurrency": "INR",
            "availability": "http://schema.org/InStock",
            "seller": {
              "@type": "Organization",
              "name": "Kukunamkeen"
            },
            "priceValidUntil": "2024-06-09",
            "priceSpecification": {
              "@type": "PriceSpecification",
              "discount": {
                "@type": "QuantitativeValue",
                "value": "120.00",
                "priceCurrency": "INR"
              }
            }
          }
        ],
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4",
          "reviewCount": "80"
        }
      })}
    </script>
    <script type="application/ld+json">
      {JSON.stringify({
        "@context": "http://schema.org",
        "@type": "Product",
        "name": "ROUND KACHORI - [500GM]",
        "description": "Ingredients - Wheat Flour, Besan, Edible Oil, Sugar, Spices & Condiments, lodized Salt, Citric Acid, Asafoetida & Bay Leaf, Fennel seeds.",
        "sku": "ROUNDKACHORI500",
        "category": "Namkeen / Kachori",
        "image": [
          "https://api.kukunamkeen.in/uploads/uploads/1713200211.jpg",
          "https://api.kukunamkeen.in/uploads/uploads/1714136829.jpg",
          "https://api.kukunamkeen.in/uploads/uploads/1717702190.jpg"
        ],
        "offers": [
          {
            "@type": "Offer",
            "price": "179.00",
            "priceCurrency": "INR",
            "availability": "http://schema.org/InStock",
            "seller": {
              "@type": "Organization",
              "name": "Kukunamkeen"
            }
          },
          {
            "@type": "Offer",
            "price": "199.00",
            "priceCurrency": "INR",
            "availability": "http://schema.org/InStock",
            "seller": {
              "@type": "Organization",
              "name": "Kukunamkeen"
            },
            "priceValidUntil": "2024-06-09",
            "priceSpecification": {
              "@type": "PriceSpecification",
              "discount": {
                "@type": "QuantitativeValue",
                "value": "20.00",
                "priceCurrency": "INR"
              }
            }
          }
        ],
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4",
          "reviewCount": "80"
        }
      })}
    </script>

    <script type="application/ld+json">
      {JSON.stringify({
        "@context": "http://schema.org",
        "@type": "Product",
        "name": "CHAPTI KACHORI - [500GM]",
        "description": "Ingredients - Wheat flour, multi grain flour, Edible Oil, Sugar, Spices & Condiments, lodized Salt, Citric Acid, asafoetida and Bay Leaf.",
        "sku": "CHAPTIKACHORI500",
        "category": "Namkeen / Kachori",
        "image": [
          "https://api.kukunamkeen.in/uploads/uploads/1713200516.jpg",
          "https://api.kukunamkeen.in/uploads/uploads/1714138881.jpg"
        ],
        "offers": [
          {
            "@type": "Offer",
            "price": "179.00",
            "priceCurrency": "INR",
            "availability": "http://schema.org/InStock",
            "seller": {
              "@type": "Organization",
              "name": "Kukunamkeen"
            }
          },
          {
            "@type": "Offer",
            "price": "199.00",
            "priceCurrency": "INR",
            "availability": "http://schema.org/InStock",
            "seller": {
              "@type": "Organization",
              "name": "Kukunamkeen"
            },
            "priceValidUntil": "2024-06-09",
            "priceSpecification": {
              "@type": "PriceSpecification",
              "discount": {
                "@type": "QuantitativeValue",
                "value": "20.00",
                "priceCurrency": "INR"
              }
            }
          }
        ],
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4",
          "reviewCount": "80"
        }
      })}
    </script> */}
    <noscript>
                    {`
                        <img height="1" width="1" style="display:none"
                             src="https://www.facebook.com/tr?id=398356959855805&ev=PageView&noscript=1" />
                    `}
                </noscript>
  </Helmet>
      <div>
        {loading ? (
          <Preloader />
        ) : (
          <BrowserRouter>
            <ScrollToTop />
            <Header />
            <Routes>
            <Route path="/login" element={<Register />} />
            <Route path="/sign-up" element={<Register />} />
            {/* <Route path="/login" element={<Login />} />
            <Route path="/sign-up" element={<Signup />} /> */}
            <Route path="/register" element={<Register />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/enterotp" element={<EnterOTP />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path="/" element={<Herosection />} />
            <Route path="/add-to-cart" element={<Cart />} />
            <Route path='/product/:slug' element={<Product />} />
            <Route path="/sweets" element={<Sweets />} />
            <Route path="/kuku-namkeen" element={<KukuNamkeen />} />
            <Route path='/checkout' element={<Checkout />} />
            <Route path='/payment-success/:id' element={<OrderPlaced />} />
            <Route path='/payment-error' element={<PaymentError />} />
            <Route path='/orders' element={<PlacedOrder />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/makingprocess" element={<Makingprocess />} />
            <Route path="/about" element={<About />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/static-page/:slug" element={<StaticPage />} />
            <Route path="/static-page/faq/" element={<Faq />} />
            <Route path="/staticpages/:slug" element={<StaticPages />} />

{/* <Route path="/" element={<UnderConstruction />} /> */}


            <Route path="/terms-conditions" element={<TermConditions />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy  />} />
            <Route path="/return-refund-policy" element={<ReturnRefund />} />
            <Route path="*" element={<NotFound />} />


            </Routes>
           
            <Footer />
          </BrowserRouter>
        )}
        <ScrollToTopButton />
      </div>
    </QueryClientProvider>
  );
}

export default App;
